export const PREDEFINED_AD_UNIT_SIZES = [
  '1x1',
  '2x2',
  '120x600',
  '160x600',
  '200x200',
  '250x250',
  '300x50',
  '300x100',
  '300x120',
  '300x250',
  '300x300',
  '300x600',
  '300x1050',
  '320x50',
  '320x100',
  '320x320',
  '320x480',
  '336x280',
  '468x60',
  '480x320',
  '640x480',
  '728x90',
  '750x100',
  '750x200',
  '750x300',
  '768x1024',
  '970x90',
  '970x100',
  '970x200',
  '970x250',
  '970x300',
  '980x120',
  '980x150',
  '980x200',
  '980x250',
  '980x300',
  '1024x768',
  '1920x1080',
] as const
