import { generatePath } from 'react-router-dom'

import { RelatedTimeoutItem } from 'components/RelatedTimeoutSettings'
import { ROUTES } from 'routes'
import { RelatedWorkspacePrebidTimeoutProps } from './types'

export const RelatedWorkspacePrebidTimeout = ({
  workspaceId,
  workspacePrebidConfiguration,
}: RelatedWorkspacePrebidTimeoutProps): JSX.Element => (
  <RelatedTimeoutItem
    message='prebidTimeout'
    url={generatePath(ROUTES.GLOBAL_SETUP.PREBID.DEFAULTS.ROOT, {
      workspaceId,
    })}
    value={workspacePrebidConfiguration.prebidTimeout}
  />
)
