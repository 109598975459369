import {
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  SliderProps as SliderPropsRoot,
  Slider as SliderRoot,
  Stack,
} from '@mui/material'
import _ from 'lodash'
import { ChangeEvent } from 'react'
import { Controller, ControllerRenderProps, FieldPath, FieldValues } from 'react-hook-form'

import { FlexBox } from 'components/Box'
import Tooltip from 'components/Tooltip'
import { SliderMark, SliderProps } from './types'

export const Slider = <T extends FieldValues>({
  control,
  label,
  marks,
  max,
  name,
  step,
  tooltip: tooltipProps,
  withInput,
  ...sliderProps
}: SliderProps<T>): JSX.Element => {
  type Field = ControllerRenderProps<T, FieldPath<T>>

  const findDescriptionMark = (value: number): SliderMark['description'] | undefined =>
    marks.find(mark => mark.description && mark.description.from <= value && mark.value >= value)
      ?.description

  const sliderMarks: SliderMark[] = [
    ...(step
      ? Array.from({ length: (max || 100) / step + 1 }, (_, index) => ({ value: index * step }))
      : []),
    ...marks,
  ]

  const handleInputOnChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: Field,
  ) => void field.onChange(Number(event.target.value) || 0)

  const handleSliderChange = (newValue: SliderPropsRoot['value'], field: Field): void => {
    if (newValue === undefined || _.isArray(newValue)) return

    for (const mark of sliderMarks) {
      if (Math.abs(newValue - mark.value) < 5) {
        field.onChange(mark.value)
        return
      }
    }

    field.onChange(newValue)
  }

  return (
    <Controller
      control={control}
      name={name as FieldPath<T>}
      render={({ field, fieldState: { error } }) => {
        const descriptionMark = findDescriptionMark(field.value)

        return (
          <Box>
            {label && (
              <FlexBox axis='x'>
                <FormLabel
                  error={Boolean(error)}
                  id={name}
                >
                  {label}
                </FormLabel>

                {tooltipProps && <Tooltip {...tooltipProps} />}
              </FlexBox>
            )}

            <FormControl error={Boolean(error)}>
              <Stack
                alignItems='center'
                direction='row'
                gap={4}
              >
                {withInput && (
                  <Input
                    {...field}
                    onChange={event => handleInputOnChange(event, field)}
                    sx={{ width: 70 }}
                    type='number'
                    value={field.value.toString()}
                  />
                )}

                <SliderRoot
                  {...field}
                  {...sliderProps}
                  color={descriptionMark?.color || 'movement'}
                  id={name}
                  marks={sliderMarks}
                  max={max}
                  onChange={(_, newValue) => handleSliderChange(newValue, field)}
                  step={step}
                  sx={{ my: 2 }}
                  valueLabelDisplay='auto'
                />
              </Stack>

              {error && <FormHelperText>{error.message}</FormHelperText>}

              {descriptionMark && (
                <FormHelperText
                  sx={{
                    color: theme => theme.palette[descriptionMark.color || 'primary'].main,
                    ml: 0,
                  }}
                >
                  {descriptionMark.title}
                </FormHelperText>
              )}
            </FormControl>
          </Box>
        )
      }}
    />
  )
}
