import { OpenInNew } from '@mui/icons-material'
import { Typography } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'

import { FlexBox } from 'components/Box'
import { Link } from 'components/Link'
import { RelatedTimeoutItemProps } from './types'

export const RelatedTimeoutItem = ({
  message,
  url,
  value,
}: RelatedTimeoutItemProps): JSX.Element => {
  const { t } = useTranslation('components')

  return (
    <FlexBox axis='x'>
      <Trans
        components={{
          bold: (
            <Typography
              fontWeight='bold'
              px={1}
            />
          ),
        }}
        i18nKey={`timeoutRelatedSettings.message.${message}`}
        t={t}
        values={{ value }}
      />

      <Link
        newTab
        to={url}
      >
        <OpenInNew
          fontSize='small'
          sx={theme => ({ color: theme.palette.info.main })}
        />
      </Link>
    </FlexBox>
  )
}
