import { ColumnDef } from '@tanstack/react-table'
import { useTranslation } from 'react-i18next'

import { BoxWithLogo } from 'components/Box'
import { LastModifiedCell } from 'components/Table'
import { COLUMN_SIZE } from 'components/Table/constants'
import { bidderLogos } from 'constants/bidderLogos'
import { DomainBidderSlice } from 'features/inventory/domain/forms/PrebidStackForm'
import { ActionsCell } from './components/ActionsCell'
import { CsEnabledCell } from './components/CsEnabledCell'
import { PriorityCell } from './components/PriorityCell'
import { S2sEnabledCell } from './components/S2sEnabledCell'

export const useColumns = (): ColumnDef<DomainBidderSlice>[] => {
  const { t } = useTranslation('features/domain', {
    keyPrefix: 'form.prebidStack.bidders.list.header',
  })

  return [
    {
      accessorKey: 'name',
      cell: ({
        row: {
          original: { name },
        },
      }) => <BoxWithLogo logo={{ size: 'small', svg: bidderLogos[name] }}>{name}</BoxWithLogo>,
      header: t('name'),
    },
    {
      accessorKey: 'code',
      header: t('code.title'),
      meta: { tooltip: { content: t('code.tooltip') } },
    },
    {
      accessorKey: 'priority',
      cell: props => <PriorityCell {...props} />,
      header: t('priority.title'),
      meta: { nonClickable: true, tooltip: { content: t('priority.tooltip') } },
    },
    {
      accessorKey: 'csEnabled',
      cell: props => <CsEnabledCell {...props} />,
      header: t('csEnabled.title'),
      meta: { centered: true, nonClickable: true, tooltip: { content: t('csEnabled.tooltip') } },
    },
    {
      accessorKey: 's2sEnabled',
      cell: props => {
        const {
          row: {
            original: { s2sAvailable },
          },
        } = props

        return s2sAvailable && <S2sEnabledCell {...props} />
      },
      header: t('s2sEnabled.title'),
      meta: { centered: true, nonClickable: true, tooltip: { content: t('s2sEnabled.tooltip') } },
    },
    {
      accessorKey: 'updatedAt',
      cell: props => <LastModifiedCell {...props} />,
      header: t('updatedAt'),
    },
    {
      accessorKey: 'actions',
      cell: props => <ActionsCell {...props} />,
      header: t('actions'),
      meta: { nonClickable: true },
      size: COLUMN_SIZE.SM,
    },
  ]
}
