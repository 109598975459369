import { CSSObject, useTheme } from '@mui/material'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

import { useTrialDuration } from 'features/product/hooks/useTrialDuration'
import { ProductProps } from 'features/product/types/productProps'
import { productStatusColor } from 'features/product/utils/productStatusColor'
import { DATE_FORMAT, formatDate } from 'utils/date'
import { UseProductLabel } from './types'

export const useProductLabel = ({ workspaceProduct }: ProductProps): UseProductLabel => {
  const { t } = useTranslation('features/product')
  const theme = useTheme()

  const { from, product, status: productStatus, to } = workspaceProduct
  const color = productStatusColor({ status: productStatus, theme })
  const { duration, trialStatus } = useTrialDuration({
    endDate: dayjs(to),
    startDate: dayjs(from),
  })

  const trialLabel = (): string | undefined => {
    if (from === undefined || to === undefined) {
      throw new Error('Trial label cannot be created. Inputs are not valid.')
    }

    if (trialStatus('expired')) {
      return t('label.title.trialExpired')
    } else if (trialStatus('pending')) {
      return t('label.title.trialStartsAt', { date: formatDate(from, DATE_FORMAT.DEFAULT) })
    } else {
      return t('label.title.trial', { duration })
    }
  }

  const chipLabel = (): string | undefined => {
    switch (productStatus) {
      case 'proInSetup':
        return t('label.title.proInSetup')
      case 'trial':
        return trialLabel()
      case 'trialInSetup':
        return t('label.title.trialInSetup')
    }
  }

  const chipTooltip = (): string => {
    const isTrial: boolean = productStatus === 'trial'

    if (isTrial && trialStatus('expired')) {
      return t('label.tooltip.trialExpired')
    } else if (isTrial) {
      return t('label.tooltip.trial')
    } else {
      return t('label.tooltip.trialInSetup', { product: t(`product.${product}`) })
    }
  }

  const chipSx: CSSObject = {
    borderColor: color,
    color,
    px: 2,
  }

  const helpIconSx: CSSObject = {
    '&, &:hover': {
      cursor: 'default',
      fill: productStatusColor({ status: productStatus, theme }),
    },
  }

  return {
    chipLabel: chipLabel(),
    chipSx,
    chipTooltip: chipTooltip(),
    helpIconSx,
  }
}
