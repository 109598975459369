import { useTranslation } from 'react-i18next'

import { TooltipProps } from 'components/Tooltip'
import { KNOWLEDGE_BASE_URL } from '../../constants'

export const useTooltips = (): Record<string, TooltipProps> => {
  const { t } = useTranslation('features/globalSetup', {
    keyPrefix: 'prebid.defaults.consentManagement',
  })
  const { t: tC } = useTranslation('common')

  return {
    gdprTcfTimeoutEnabled: {
      content: t('gdprTcfTimeoutEnabled.tooltip'),
      link: {
        title: tC('learnMore'),
        url: KNOWLEDGE_BASE_URL.GDPR_TCF_TIMEOUT_ENABLED,
      },
    },
    gppControlUsNat: {
      content: t('gppControlUsNat.tooltip'),
      link: {
        title: tC('learnMore'),
        url: KNOWLEDGE_BASE_URL.GPP_CONTROL_US_NAT,
      },
    },
    gppControlUsStates: {
      content: t('gppControlUsStates.tooltip'),
      link: {
        title: tC('learnMore'),
        url: KNOWLEDGE_BASE_URL.GPP_CONTROL_US_STATES,
      },
    },
    gppTimeoutEnabled: {
      content: t('gppTimeoutEnabled.tooltip'),
      link: {
        title: tC('learnMore'),
        url: KNOWLEDGE_BASE_URL.GPP_TIMEOUT_ENABLED,
      },
    },
    usPrivacyCcpaTimeoutEnabled: {
      content: t('usPrivacyCcpaTimeoutEnabled.tooltip'),
      link: {
        title: tC('learnMore'),
        url: KNOWLEDGE_BASE_URL.US_PRIVACY_CCPA_TIMEOUT_ENABLED,
      },
    },
  } as const
}
