import { useQuery } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { GET_ASTERIO_BID_TOKEN } from './api/query'
import { Token, UseGetAsterioBidToken } from './types'

export const useGetAsterioBidToken = (): UseGetAsterioBidToken => {
  const { t } = useTranslation('features/performance', { keyPrefix: 'prebidStack' })
  const { enqueueSnackbar } = useSnackbar()
  const [token, setToken] = useState<Token>()

  const { loading } = useQuery(GET_ASTERIO_BID_TOKEN, {
    onCompleted: (token: string): void => {
      if (!token) {
        enqueueSnackbar(t('asterioBid.error.connection'), { variant: 'error' })
      } else {
        setToken(token)
      }
    },
  })

  return {
    loading,
    token,
  }
}
