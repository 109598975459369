import { generatePath } from 'react-router-dom'

import { RelatedTimeoutItem } from 'components/RelatedTimeoutSettings'
import { ROUTES } from 'routes'
import { RelatedFailsafeTimeoutProps } from './types'

export const RelatedFailsafeTimeout = ({ domain }: RelatedFailsafeTimeoutProps): JSX.Element =>
  domain.customFailsafeTimeoutEnabled ? (
    <RelatedTimeoutItem
      message='customFailsafeTimeout'
      url={generatePath(ROUTES.INVENTORY.DOMAIN.EDIT, {
        id: domain.id,
        tab: 'general',
        workspaceId: domain.workspace.id,
      })}
      value={domain.failsafeTimeout}
    />
  ) : (
    <RelatedTimeoutItem
      message='failsafeTimeout'
      url={generatePath(ROUTES.GLOBAL_SETUP.GENERAL.ROOT, {
        tab: 'workspace',
        workspaceId: domain.workspace.id,
      })}
      value={domain.workspace.failsafeTimeout}
    />
  )
