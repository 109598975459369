import { SxProps, Theme } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { BoxForm, NumberField, Switch } from 'components/Form'
import { SectionHeader } from 'components/Header'
import { TextLink } from 'components/Link'
import Paper from 'components/Paper'
import { CONTAINER_SPACING } from 'providers/material-ui/theme/constants'
import { KNOWLEDGE_BASE_URL } from '../../constants'
import { Schema } from '../../schema'
import { useTooltips } from './useTooltips'

export const ConsentManagementSection = (): JSX.Element => {
  const { t } = useTranslation('features/globalSetup', {
    keyPrefix: 'prebid.defaults.consentManagement',
  })
  const { t: tC } = useTranslation('common')
  const { control, watch } = useFormContext<Schema>()
  const { gdprTcfTimeoutEnabled, gppTimeoutEnabled, usPrivacyCcpaTimeoutEnabled } = watch()
  const tooltips = useTooltips()

  const sx: SxProps<Theme> = {
    ml: CONTAINER_SPACING,
  }

  return (
    <Paper>
      <BoxForm>
        <SectionHeader
          subtitle={t('subtitle')}
          subtitleEndAdornment={
            <TextLink
              link={{
                title: tC('learnMore'),
                url: KNOWLEDGE_BASE_URL.CONSENT_MANAGEMENT,
              }}
            />
          }
          title={t('title')}
        />

        <Switch
          control={control}
          label={t('gdprTcfTimeoutEnabled.label')}
          labelPlacement='start'
          name='gdprTcfTimeoutEnabled'
          tooltip={tooltips.gdprTcfTimeoutEnabled}
        />

        {gdprTcfTimeoutEnabled && (
          <NumberField
            control={control}
            label={t('gdprTcfTimeout.label')}
            name='gdprTcfTimeout'
            sx={sx}
          />
        )}

        <Switch
          control={control}
          label={t('usPrivacyCcpaTimeoutEnabled.label')}
          labelPlacement='start'
          name='usPrivacyCcpaTimeoutEnabled'
          tooltip={tooltips.usPrivacyCcpaTimeoutEnabled}
        />

        {usPrivacyCcpaTimeoutEnabled && (
          <NumberField
            control={control}
            label={t('usPrivacyCcpaTimeout.label')}
            name='usPrivacyCcpaTimeout'
            sx={sx}
          />
        )}

        <Switch
          control={control}
          label={t('gppTimeoutEnabled.label')}
          labelPlacement='start'
          name='gppTimeoutEnabled'
          tooltip={tooltips.gppTimeoutEnabled}
        />

        {gppTimeoutEnabled && (
          <BoxForm sx={sx}>
            <NumberField
              control={control}
              label={t('gppTimeout.label')}
              name='gppTimeout'
            />

            <Switch
              control={control}
              label={t('gppControlUsNat.label')}
              labelPlacement='start'
              name='gppControlUsNat'
              tooltip={tooltips.gppControlUsNat}
            />

            <Switch
              control={control}
              label={t('gppControlUsStates.label')}
              labelPlacement='start'
              name='gppControlUsStates'
              tooltip={tooltips.gppControlUsStates}
            />
          </BoxForm>
        )}
      </BoxForm>
    </Paper>
  )
}
