import { mapDTO } from '@twistezo/ts-dto-mapper'
import _ from 'lodash'

import { CommonSchema } from 'features/globalSetup/forms/Prebid/Modules/schemas/commonSchema'
import { parseValidJSON } from 'utils/json'

type From = CommonSchema
type To = Record<string, unknown>

export const commonSchemaParametersToDTO = (from: From): To =>
  mapDTO<From, To>({ from }).transform((data: From): To => {
    const params = _.omit(data, ['accountType', 'prebidModuleId'])

    const parsedParams: Record<string, unknown> = Object.fromEntries(
      Object.entries(params).map(
        ([key, value]: [key: string, value: boolean | number | string]): [string, unknown] => [
          key,
          parseValidJSON(value.toString()),
        ],
      ),
    )

    return parsedParams
  })
