import { mapDTO } from '@twistezo/ts-dto-mapper'

import { composeUsername } from 'features/user/utils/composeUsername'
import { EmployeeForTable } from '../../../types'
import { EmployeeForTableDTO } from './types'

export const employeeForTableFromDTO = (from: EmployeeForTableDTO): EmployeeForTable =>
  mapDTO<EmployeeForTableDTO, EmployeeForTable>({ from }).transform(dto => {
    const { email, firstName, id, lastName, role, status, updatedAt } = dto

    return {
      email,
      id,
      role,
      status,
      updatedAt: new Date(updatedAt),
      username: composeUsername({ firstName, lastName }),
    }
  })
