import _ from 'lodash'

import { AdUnitSize } from 'features/inventory/viewport'
import { stringifiedToAdUnitSize } from 'features/inventory/viewport/utils'
import { EditSchema } from '../../../schemas/editSchema'
import { MediaTypeViewportsSchema } from '../../../schemas/mediaTypesSchema'
import { MediaTypeAttributeDTO, Variables } from './types'

const defaultAttributeToDTO = ({
  bannerEnabled,
  bannerSizes,
  mediaTypeId,
  nativeEnabled,
  outstreamEnabled,
  playerSize,
  viewportId,
}: EditSchema['mediaTypes']['defaultAttribute']): MediaTypeAttributeDTO => ({
  bannerEnabled,
  bannerSizes: bannerSizes.map((size: string): AdUnitSize => stringifiedToAdUnitSize(size)),
  id: mediaTypeId,
  nativeEnabled,
  outstreamEnabled,
  playerSize: playerSize.map((size: string): AdUnitSize => stringifiedToAdUnitSize(size)),
  viewportId,
})

const viewportAttributesToDTO = (
  viewportAttributes: EditSchema['mediaTypes']['viewportAttributes'],
): Variables['mediaTypes']['viewportAttributes'] =>
  viewportAttributes.map((viewportAttribute: MediaTypeViewportsSchema): MediaTypeAttributeDTO => {
    const defaultAttribute = _.pick(viewportAttribute, [
      'bannerEnabled',
      'bannerSizes',
      'mediaTypeId',
      'nativeEnabled',
      'outstreamEnabled',
      'playerSize',
      'viewportId',
      'updatedAt',
    ])

    return defaultAttributeToDTO(defaultAttribute)
  })

export const mapper = (data: EditSchema): Variables => {
  const {
    divId,
    mediaTypes: {
      defaultAttribute,
      kind,
      nativeConfiguration,
      outstreamConfiguration,
      viewportAttributes,
    },
    name,
  } = data

  return {
    ..._.omit(data, 'domainId', 'path', 'rawId', 'unfilledRecoveryAdUnit'),
    divId: divId || null,
    mediaTypes: {
      defaultAttribute: kind === 'default' ? defaultAttributeToDTO(defaultAttribute) : undefined,
      ignoreViewportAttributes: kind === 'default' ? true : false,
      nativeConfiguration: JSON.parse(nativeConfiguration),
      outstreamConfiguration: JSON.parse(outstreamConfiguration),
      viewportAttributes:
        kind === 'viewports' ? viewportAttributesToDTO(viewportAttributes) : undefined,
    },
    name: name || null,
  }
}
