import { ColumnDef } from '@tanstack/react-table'
import { sentenceCase } from 'change-case'
import { upperFirst } from 'lodash'
import { useTranslation } from 'react-i18next'

import { BoxWithLogo } from 'components/Box'
import { COLUMN_SIZE } from 'components/Table/constants'
import { bidderLogos } from 'constants/bidderLogos'
import { ActionsCell } from './components/ActionsCell'
import { EnabledCell } from './components/EnabledCell'
import { BiddersForTable } from './types'

export const useColumns = (): ColumnDef<BiddersForTable>[] => {
  const { t } = useTranslation(['features/globalSetup', 'common'])

  return [
    {
      accessorKey: 'name',
      cell: ({
        row: {
          original: { name },
        },
      }) => <BoxWithLogo logo={{ size: 'small', svg: bidderLogos[name] }}>{name}</BoxWithLogo>,
      header: t('prebid.bidders.list.header.name'),
    },
    {
      accessorKey: 'code',
      header: t('prebid.bidders.list.header.code'),
    },
    {
      accessorKey: 'accountType',
      cell: ({ row }) => upperFirst(row.original.accountType),
      header: t('prebid.bidders.list.header.account'),
    },
    {
      accessorKey: 'bidCpmAdjustment',
      cell: ({
        row: {
          original: { bidCpmAdjustment: value },
        },
      }) => t('common:percentage', { value }),
      header: t('prebid.bidders.list.header.bidCPMAdjustment'),
    },
    {
      accessorKey: 'consentMode',
      cell: ({ getValue }) =>
        t(`prebid.bidders.list.consentMode.${getValue<BiddersForTable['consentMode']>()}`),
      header: t('prebid.bidders.list.header.consentMode'),
    },
    {
      accessorKey: 'priority',
      cell: ({ getValue }) => sentenceCase(getValue<BiddersForTable['priority']>()),
      header: t('prebid.bidders.list.header.priority'),
    },
    {
      accessorKey: 'enabled',
      cell: props => <EnabledCell {...props} />,
      header: t('prebid.bidders.list.header.enabled'),
      meta: { nonClickable: true },
    },
    {
      accessorKey: 'actions',
      cell: props => <ActionsCell {...props} />,
      header: t('prebid.bidders.list.header.actions'),
      meta: { nonClickable: true },
      size: COLUMN_SIZE.SM,
    },
  ]
}
