import { generatePath } from 'react-router-dom'

import { RelatedTimeoutItem } from 'components/RelatedTimeoutSettings'
import { ROUTES } from 'routes'
import { RelatedWorkspaceFailsafeTimeoutProps } from './types'

export const RelatedWorkspaceFailsafeTimeout = ({
  workspace,
}: RelatedWorkspaceFailsafeTimeoutProps): JSX.Element => (
  <RelatedTimeoutItem
    message='failsafeTimeout'
    url={generatePath(ROUTES.GLOBAL_SETUP.GENERAL.ROOT, {
      tab: 'workspace',
      workspaceId: workspace.id,
    })}
    value={workspace.failsafeTimeout}
  />
)
