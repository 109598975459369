import { Button, ButtonProps } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import Filter, { FilterValue } from 'components/Filter'
import { CommonFormContextProps, SaveButton, useCommonFormContextProps } from 'components/Form'
import { PageHeader } from 'components/Header'
import { TextLink } from 'components/Link'
import Paper from 'components/Paper'
import { TableHeader } from 'components/Table'
import { useAbility } from 'providers/casl'
import { ViewportTable } from '../../components/ViewportTable'
import { ViewportPageContext, ViewportPageContextType } from '../../contexts/ViewportPageContext'
import { ViewportForm } from '../../forms/ViewportForm'
import { KNOWLEDGE_BASE_URL } from './constants'

export const ViewportPage = (): JSX.Element => {
  const { t } = useTranslation(['features/viewport', 'common'])
  const ability = useAbility()
  const [filters, setFilters] = useState<FilterValue[]>([])
  const [onAddNew, setOnAddNew] = useState<ViewportPageContextType['onAddNew']>(() => () => {})

  const commonContextProps: CommonFormContextProps = useCommonFormContextProps({
    formId: 'inventory-viewports-form',
  })
  const contextProps: ViewportPageContextType = {
    filters,
    onAddNew,
    setOnAddNew,
    ...commonContextProps,
  }

  const DescriptionLink: JSX.Element = (
    <TextLink
      link={{
        title: t('common:learnMore'),
        url: KNOWLEDGE_BASE_URL,
      }}
    />
  )

  const ViewportsFilter: JSX.Element = (
    <Filter
      id='viewports-filter'
      onChange={setFilters}
      placeholder={t('filter')}
      values={filters}
    />
  )

  const AddButton = ({ onClick }: { onClick: ButtonProps['onClick'] }): JSX.Element => (
    <Button
      data-cy='viewport-add'
      onClick={onClick}
      variant='contained'
    >
      {t('common:actions.addNew')}
    </Button>
  )

  const canCreateUpdateDelete: boolean =
    ability.can('create', 'Viewport') &&
    ability.can('update', 'Viewport') &&
    ability.can('delete', 'Viewport')

  return (
    <ViewportPageContext.Provider value={contextProps}>
      <ViewportPageContext.Consumer>
        {(ctxProps: ViewportPageContextType): JSX.Element => (
          <>
            {canCreateUpdateDelete && <PageHeader actions={<SaveButton {...ctxProps} />} />}

            <Paper>
              <TableHeader
                actions={
                  canCreateUpdateDelete ? <AddButton onClick={ctxProps.onAddNew} /> : undefined
                }
                bottomSpacing
                description={t('header.description')}
                descriptionEndAdornment={DescriptionLink}
                filter={ViewportsFilter}
                title={t('header.title')}
              />

              <ViewportForm>
                <ViewportTable />
              </ViewportForm>
            </Paper>
          </>
        )}
      </ViewportPageContext.Consumer>
    </ViewportPageContext.Provider>
  )
}
