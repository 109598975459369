import { z } from 'zod'

import { AdUnitSize } from './types/adUnitSize'

export const stringifyAdUnitSize = (size: AdUnitSize): string => `${size.width}x${size.height}`

export const stringifiedToAdUnitSize = (size: string): AdUnitSize => {
  const [width, height] = size.split('x')

  return {
    height: parseInt(height),
    width: parseInt(width),
  }
}

export const validateSize = (size: string): boolean => /^\d+x\d+$/.test(size)

export const validateSizes = (sizes: string[], ctx: z.RefinementCtx): void => {
  sizes.forEach(val => {
    if (val === 'fluid') {
      return
    }

    if (!validateSize(val)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: [val],
      })
    }
  })
}
