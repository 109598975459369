import { ColumnDef } from '@tanstack/react-table'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { COLUMN_SIZE } from 'components/Table/constants'
import { Schema } from 'features/globalSetup/pages/Prebid/Bidders/EditBidderPage/pages/BidParamsAndConnection/form/schema'
import { EnableBidderToggleCell } from '../../cells/EnableBidderToggleCell'
import { AdUnitsForTable } from '../../types'
import { UseEnableBidderColumnsProps } from './types'

export const useEnableBidderColumns = ({
  requiredParams,
  s2sParamsInitial,
  workspaceStatus,
}: UseEnableBidderColumnsProps): ColumnDef<AdUnitsForTable> => {
  const { t } = useTranslation(['features/globalSetup'], {
    keyPrefix: 'prebid.bidders.edit.bidParamsAndConnection.adUnitsTable',
  })

  const { control } = useFormContext<Schema>()

  const csColumn: ColumnDef<AdUnitsForTable> = {
    accessorKey: 'csEnabled',
    cell: context => (
      <EnableBidderToggleCell
        cellContext={context}
        control={control}
        kind='cs'
        requiredParams={requiredParams}
        workspaceStatus={workspaceStatus}
      />
    ),
    header: t('enableBidder.cs'),
    meta: { centered: true },
    size: COLUMN_SIZE.SM,
  }

  const s2sColumn: ColumnDef<AdUnitsForTable> = {
    accessorKey: 's2sEnabled',
    cell: context => (
      <EnableBidderToggleCell
        cellContext={context}
        control={control}
        kind='s2s'
        requiredParams={requiredParams}
        workspaceStatus={workspaceStatus}
      />
    ),
    header: t('enableBidder.s2s'),
    meta: { centered: true },
    size: COLUMN_SIZE.SM,
  }

  const columns: ColumnDef<AdUnitsForTable>[] =
    s2sParamsInitial === undefined ? [csColumn] : [csColumn, s2sColumn]

  return {
    columns,
    header: t('enableBidder.group'),
  }
}
