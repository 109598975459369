import { ColumnDef } from '@tanstack/react-table'
import { upperFirst } from 'lodash'
import { useTranslation } from 'react-i18next'

import { BoxWithLogo } from 'components/Box'
import { LastModifiedCell } from 'components/Table'
import { COLUMN_SIZE } from 'components/Table/constants'
import { prebidModuleLogos } from 'features/globalSetup/forms/Prebid/Modules/logos/prebidModuleLogos'
import { WorkspacePrebidModuleSlice } from 'features/globalSetup/pages/Prebid/Modules/hooks/useGetWorkspacePrebidModules'
import { ActionsCell } from './components/ActionsCell'
import { EnabledCell } from './components/EnabledCell'

export const useColumns = (): ColumnDef<WorkspacePrebidModuleSlice>[] => {
  const { t } = useTranslation('features/globalSetup')

  return [
    {
      accessorKey: 'prebidModule.name',
      cell: ({
        row: {
          original: {
            prebidModule: { name },
          },
        },
      }) => (
        <BoxWithLogo logo={{ size: 'small', svg: prebidModuleLogos[name] }}>{name}</BoxWithLogo>
      ),
      header: t('prebid.modules.userId.list.header.name'),
    },
    {
      accessorKey: 'prebidModule.account',
      cell: ({ row }) => upperFirst(row.original.prebidModule.account),
      header: t('prebid.modules.userId.list.header.account'),
    },
    {
      accessorKey: 'enabled',
      cell: props => <EnabledCell {...props} />,
      header: t('prebid.modules.userId.list.header.enabled'),
    },
    {
      accessorKey: 'updatedAt',
      cell: props => <LastModifiedCell {...props} />,
      header: t('prebid.modules.userId.list.header.lastModified'),
    },
    {
      cell: ({ row }) => <ActionsCell {...row.original} />,
      header: t('prebid.modules.userId.list.header.actions'),
      id: 'actions',
      meta: {
        centered: true,
        nonClickable: true,
      },
      size: COLUMN_SIZE.SM,
    },
  ]
}
