import { List, ListItem } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { AlertBox } from 'components/Box'
import { RelatedTimeoutSettingsProps } from './types'

export const RelatedTimeoutSettings = ({ items }: RelatedTimeoutSettingsProps): JSX.Element => {
  const { t } = useTranslation('components')

  return (
    <AlertBox
      severity='info'
      title={t('timeoutRelatedSettings.title')}
    >
      <List
        dense
        sx={{
          listStyleType: 'disc',
          ml: 2,
        }}
      >
        {items.map(
          (item: JSX.Element, index: number): JSX.Element => (
            <ListItem
              disableGutters
              key={index}
              sx={{ display: 'list-item' }}
            >
              {item}
            </ListItem>
          ),
        )}
      </List>
    </AlertBox>
  )
}
