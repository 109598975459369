import { useContext } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Switch } from 'components/Form'
import { SectionHeader } from 'components/Header'
import Paper from 'components/Paper'
import { InventoryAmazonUamTamSection } from 'features/globalSetup/features/amazon'
import ProductAdUnitsTable from '../../components/ProductAdUnitsTable'
import { AddEditDomainContext } from '../../contexts/AddEditDomainContext'
import { Schema } from './schema'
import { BiddersSection } from './sections/BiddersSection'
import { ModulesSection } from './sections/ModulesSection'
import { TimeoutSection } from './sections/TimeoutSection'

export const Form = (): JSX.Element => {
  const { t } = useTranslation(['features/domain', 'common'])
  const { domain } = useContext(AddEditDomainContext)
  const { control } = useFormContext<Schema>()

  if (!domain) {
    throw Error('Domain should be accessible')
  }

  const {
    domainPrebidStack: { domainAmazonEnabled },
    id,
  } = domain

  return (
    <>
      <Paper>
        <SectionHeader
          subtitle={t('form.prebidStack.enabled.description')}
          title={t('form.prebidStack.enabled.title')}
          titleEndAdornment={
            <Switch
              control={control}
              name='prebidStackEnabled'
            />
          }
        />
      </Paper>

      <InventoryAmazonUamTamSection<Schema>
        amazonState={domainAmazonEnabled}
        feature='domain'
      />

      <TimeoutSection />

      <Controller
        control={control}
        name='domainBiddersAttributes'
        render={() => <BiddersSection />}
      />

      <Controller
        control={control}
        name='domainPrebidModulesAttributes'
        render={() => <ModulesSection />}
      />

      <Controller
        control={control}
        name='adUnitsAttributes'
        render={() => (
          <ProductAdUnitsTable
            domainId={id}
            product='prebidStack'
          />
        )}
      />
    </>
  )
}
