import _ from 'lodash'
import { z } from 'zod'

import { WorkspacePrebidConfiguration } from 'features/globalSetup/types/workspacePrebidConfiguration'
import { Workspace } from 'features/workspace'

export const schema = z.object({
  gdprTcfTimeout: z.number().min(0),
  gdprTcfTimeoutEnabled: z.boolean(),
  gppControlUsNat: z.boolean(),
  gppControlUsStates: z.boolean(),
  gppTimeout: z.number().min(0),
  gppTimeoutEnabled: z.boolean(),
  prebidTimeout: z.number().min(0),
  usPrivacyCcpaTimeout: z.number().min(0),
  usPrivacyCcpaTimeoutEnabled: z.boolean(),
  workspaceId: z.string(),
})

export type Schema = z.infer<typeof schema>

export const schemaDefault = (
  workspacePrebidConfiguration: WorkspacePrebidConfiguration,
  workspaceId: Workspace['id'],
): Schema => {
  const fields: Pick<WorkspacePrebidConfiguration, keyof Omit<WorkspacePrebidConfiguration, 'id'>> =
    _.pick(workspacePrebidConfiguration, [
      'gdprTcfTimeout',
      'gdprTcfTimeoutEnabled',
      'gppControlUsNat',
      'gppControlUsStates',
      'gppTimeout',
      'gppTimeoutEnabled',
      'prebidTimeout',
      'usPrivacyCcpaTimeout',
      'usPrivacyCcpaTimeoutEnabled',
    ])

  return {
    ...fields,
    workspaceId,
  }
}
